<template>
<b-card
    no-body
  >
    <b-card-body>
      <b-card-title>Permissions</b-card-title>
      <b-card-sub-title>Permission according to roles</b-card-sub-title>
      <!-- basic select -->
    <b-col md="6">
      <b-form-group>
        <v-select
          v-model="selected"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="option"
        />
      </b-form-group>
    </b-col>
    </b-card-body>
    <b-table
      striped
      responsive
      :items="permissionsData"
      class="mb-0"
    >

      <template #cell(module)="data">
        {{ data.value }}
      </template>
      <template #cell()="data">
        <b-form-checkbox
          :checked="data.value"
        />
      </template>

    </b-table>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import {
  BCard,
  BTable,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BFormGroup,
    BFormInput,
    BForm,
    BCol,
    BRow,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
     BFormGroup,
     BForm,
    BFormInput,
    vSelect,
    BCol,
    BRow
  },
  setup() {
    const permissionsData = [
      {
        module: "Clientes",
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: "Dashboard",
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: "Author",
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: "Contributor",
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: "User",
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ];
    const roleOptions = [
      { label: 'Administrador', value: 'admin' },
      { label: 'Integrador', value: 'integrator' },
      { label: 'Consultor', value: 'consumer' },
    ]

    return {
      permissionsData,
      roleOptions,
    };
  },
  data(){
    return {
      selected: { title: 'Integrador' },
      option: [{ title: 'Administrador' }, { title: 'Integrador' }, { title: 'Consultor' }],
    }
  }
};
</script>
<style>
</style>